// General

$bgcolor: #fbfbfb;
$textcolor: #222222;
$fontsize: 18px;
$border: 3px solid $textcolor;
$padding: 10px 20px 10px 20px;

@mixin default_text {
    color: $textcolor;
    font: {
        family: 'Roboto';
        size: $fontsize;
        weight: 500;
    }
}

@mixin text_header {
    font: {
        family: 'Roboto';
        size: 30px;
        weight: 700;
    }
}

@mixin shadow {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 1);
    box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 1);
    border-radius: 10px;
}

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    overscroll-behavior: contain;
}

#root, #main-container {
    min-height: 100vh;
}

#main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
}

#main-wrapper  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

// Header

header {
    font-size: 0;
    text-align: center;
}

.logo  {
    width: 90%;
    max-width: 400px;
    margin: 25px;
}

#app1-link-parent  {
    text-decoration: none;
}

#app1-link  {
    display: block;
    background-color: rgb(137, 216, 186); 
    margin: auto;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
    color: black;
    padding: 15px 10px;
    text-align: center;
    @include default_text;
    font-weight: 600;
    font-size: 24px;
}

#logout  {
    display: flex;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    @include default_text;
    max-width: 150px;
    margin: auto;
}

#logout:hover  {
    font-weight: 600;
}

#logout-img  {
    margin-left: 5px;
    width: 21px;
}

// Loading

#loading-outer-container  {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#loading-inner-container  {
    text-align: center;
}

.waiting-message  {
    @include default_text();
    margin-top: 0;
}

// Admin panel













.admin-user-table-mobile  {
    @include default_text();
    font-size: 16px;
    border-collapse: collapse;
    border: 3px solid #8cbbb0;
    margin: auto;
    margin-bottom: 25px;
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
    display: none;
}

.admin-user-table-mobile th, .admin-user-table-mobile td {
    padding: 10px;
    text-align: center;
    border: 3px solid #8cbbb0;
    line-height: 24px;
    height: 56px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.current-user-table  {
    box-shadow: 0px 0px 5px 2px rgba(0,119,30,0.75);
    -webkit-box-shadow: 0px 0px 5px 2px rgba(0,119,30,0.75);
    -moz-box-shadow: 0px 0px 5px 2px rgba(0,119,30,0.75);
}

.action-not-available-icon  {
    display: flex;
    width: 100%;
    justify-content: center;
}

.user-list-table thead {
    background-color: #9cc7bd;
}

.user-edit-input  {
    padding: 5px;
    text-align: center;
    @include default_text();
    font-size: 16px;
    width: 100%;
    background-color: #eee;
    border: 1px solid #777;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
}

.user-edit-input:focus {
    outline: 0;
}

.edit-user-button  {
    border-radius: 5px;
    background: #D1D1D4;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
    cursor: pointer;
    border: none;
}

.edit-user-button:hover  {
    background: #dbdbdd;
}

.delete-user-button {
    cursor: pointer;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
    background: #e62222;
   }
   
.delete-user-button, .delete-user-button span {
    transition: 200ms;
}
   
.edit-user-icon {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
   
.delete-user-button svg {
    width: 15px;
    fill: #eee;
}
   
.delete-user-button:hover {
    background: #ff3636;
}
   
.delete-user-button:hover .text {
    color: transparent;
}
    
.delete-user-button:focus {
    outline: none;
}
   
.delete-user-button:active .icon svg {
    transform: scale(0.8);
}

.user-edit-textarea  {
    min-height: 100px;
}

.table-paragraph  {
    margin: 0;
    word-break: break-all;
}

.not-visible  {
    display: none;
}





.assembly-camera-message, .assembly-camera-warning-message  {
    @include default_text();
    margin-bottom: 0;
}

.assembly-camera-warning-message  {
    color: #ff0000;
}

.camera-messages-modal-wrapper  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}









#admin-wrapper  {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 25px;
}

#admin-menu-wrapper  {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 10px;
}

.admin-menu-item  {
    @include default_text;
    width: 200px;
    text-align: center;
    padding: 10px 20px 10px 20px;
    background: #089992;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
    shape-outside: polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}

.admin-menu-item:hover, .admin-menu-active  {
    background: #056863;
}

#admin-outer-container  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

#admin-inner-container  {
    text-align: center;
    padding: 25px;
    @include shadow;
    max-width: 95vw;
}

#admin-site-title  {
    @include default_text;
    font-weight: 600;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 25px;
    text-align: center;
}

#admin-user-table, .data-table  {
    table-layout: fixed;
    margin: auto;
    border: 1px solid #222222;
    border-collapse: collapse;
}

#admin-user-table th, td, .data-table th, td {
    padding: 10px;
    text-align: center;
    border: 1px solid #222222;
}

.user-delete  {
    cursor: pointer;
    color: #ff0000;
    font-weight: 600;
}

#add-user-button  {
    padding: 10px 15px 10px 15px;
    background-color: #2ea44f;
    color: #fff;
    font-weight: 600;
    border: 0;
    border-radius: 5px;
    margin-top: 25px;
    cursor: pointer;
}

#add-user-button:hover  {
    color: #c6c6c6;
}

.user-form, #user-role-select  {
    width: 80%;
    margin: auto;
    margin-bottom: 10px;
    height: 35px;
    border: 1px solid #888;
    padding: 5px;
}

.user-error-message  {
    @include default_text;
    font-weight: 600;
    color: #ff0000;
    text-align: center;
}

// Mobile menu

#menu-small-screen  {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    background-color: #089992;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: space-around;
    padding: 10px;
    align-items: center;
}

#menu-small-screen svg  {
    width: 35px;
}

.mobile-icon-outer-wrapper  {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-icon-outer-wrapper-active  {
    background-color: #fff;
    border-radius: 50%;
}

.mobile-icon-outer-wrapper-active svg path {
    fill: #089992!important;
}

.main-menu-icon  {
    fill: #fff;
}

#marker-bottom-menu-smallscreen  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#marker-bottom-menu-outer-wrapper  {
    margin-top: 20px;
}

.marker-bottom-menu-no-margin  {
    margin-top: 0!important;
}

#mobile-marker-menu-modal, .assembly-menu-modal  {
    padding-top: 25px;
}

.assembly-menu-modal-close-wrapper  {
    position: relative;
}

.assembly-menu-modal-close-button  {
    position: absolute;
    top: -15px;
    right: 10px;
    border: 0;
    background-color: transparent;
    font-size: 22px;
    font-weight: 600;
    cursor: pointer;
}

.assembly-menu-modal  {
    width: 80vw;
}

.assembly-modal-top-wrapper  {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.mobile-marker-menu-button, .assembly-menu-button  {
    background-color: transparent;
    display: flex;
    padding: 10px;
    align-items: center;
    @include default_text();
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 2px solid #222;
    width: 100%;
}

.assembly-menu-button  {
    justify-content: center;
    padding: 15px;
}

.mobile-menu-top-border  {
    display: block;
    width: 100%;
    border-top: 2px solid #222;
}

.mobile-marker-menu-icon  {
    width: 25px;
    height: 25px;
    margin-left: 15px;
    margin-right: 15px;
}

.mobile-camera-button  {
    display: flex;
    align-items: center;
    padding: 5px 15px 5px 15px;
    background-color: #48b02c;
    border-radius: 5px;
    border: 0;
    margin-top: 10px;
}

.mobile-camera-button img  {
    width: 25px;
    margin-right: 10px;
}

.mobile-camera-button span {
    color: #fff;
    font-weight: 600;
}

// Main page

#map-outer-container  {
    display: flex;
    margin: auto;
    max-width: 1600px;
    width: 95%;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 25px;
}
//////////////////////////////////////////////////////////////////////////////////////////
#side-panel  {
    flex-basis: 27.5%;
    display: flex;
    flex-direction: column;
    @include shadow;
}

.side-panel-active  {
    max-height: 780px;
    overflow-y: auto;
    //scrollbar-width: none;
    -ms-overflow-style: none;
}

#main-panel  {
    flex-basis: 70%;
}

#map-inner-container  {
    position: relative;
}

#search-bar-wrapper  {
    position: absolute;
    top: 10px;
    left: 200px;
}

#search-bar-input  {
    padding: 10px;
    height: 40px;
    border: 0;
    border-left: 3px solid #f3f3f3;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.selected-buttons-wrapper  {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.selected-place-button  {
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.selected-place-button-disabled  {
    opacity: 0.4;
}

.selected-place-button img  {
    max-width: 40px;
}

.title-container  {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.menu-title  {
    @include default_text;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    flex-basis: 80%;
    margin: 0;
}

.button-container  {
    flex-basis: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //position: relative;
}

.vertical-button  {
    height: 20px;
    width: 20px;
    border: 0;
    background-color: transparent;
    background-image: url('../images/icon1.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.menu-toggleable  {
    position: absolute;
    z-index: 100;
    width: 200px;
    right: 20px;
    border: 1px solid #888;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 1);
    box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 1);
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #fff;
}

.menu-toggleable-top  {
    top: 0px;
}

.menu-toggleable-bottom  {
    bottom: 0px;
}

.menu-item, .project-name  {
    font-family: 'Roboto';
    user-select: none;
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: 15px;
}

.menu-item:hover, .project-name:hover  {
    background-color: #f1f1f1;
}

#layer-top-container  {
    text-align: center;
    padding: 15px;
    background-color: #e8e8e8;
    border-bottom: 1px solid #c5bcbc;
}

.layer-sort-wrapper  {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.layer-sort-wrapper select  {
    //max-height: 25px;
    background-color: #e9e9ed;
    border: 1px solid #979797;
}

#sort-selection  {
    padding: 5px;

}

.project-additional-info  {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #c5bcbc;
}

.project-description  {
    margin-top: 15px;
    margin-bottom: 15px;
}

.project-description:first-of-type  {
    margin-top: 0;
}

#add-layer-button, .color-button {
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: 'Roboto';
  padding: 10px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
}

#add-layer-button  {
    padding: 10px 15px;
}

#add-layer-button:hover, .color-button:hover  {
    font-weight: 600;
    background-image: linear-gradient(#00c8e7, #0069e9);
}

.add-layer-spinner svg {
    width: 16px;
    height: 16px;
    margin-left: 3px;
    margin-bottom: -2px;
}

#pick-color  {
    margin-top: 15px;
    margin-bottom: 15px;
}

.color-button  {
    padding: 5px 10px;
}

#accept-color-button  {
    margin-top: 15px;
}

// Layer

#layer-container  {
    flex-grow: 1;
    overflow-y: auto;
}
 /*
.layer-container-extended  {
    padding-bottom: 110px;
}

*/

.layer-wrapper  {
    padding: 20px;
    padding-bottom: 0;
    border-bottom: 1px solid #f1f1f1;
}


#layer_rename  {
    margin: 5px;
}

.layer-active  {
    background-color: #f1f1f1;
}

.layer-error-message  {
    @include default_text;
    font-weight: 600;
    color: #ff0000;
    text-align: center;
}

#side-sticky-wrapper  {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
}

.layer-top-panel  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

#markers-sort  {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Roboto';
    margin: 0;
}

.layer-top-wrapper  {
    display: flex;
}

.toggle-layer  {
    margin-right: 25px;
}

.layer-title  {
    @include default_text;
    font-size: 15px;
}

.layer-button-wrapper  {
    display: flex;
    margin-left: 20px;
    align-items: flex-start;
    position: relative;
}

.quick-layer-hide-button  {
    height: 20px;
    width: 20px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    margin-right: 10px;
}

.layer-hidden  {
    display: none;
}

.marker-list-item  {
    font-family: 'Roboto';
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    display: inline-block;
}

.marker-list-item:hover  {
    font-weight: 500;
}

.marker-active  {
    font-weight: 500;
    color: #008d67;
}

// Marker panel

#marker-panel  {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    padding: 25px;
    @include shadow;
    min-height: 300px;
    justify-content: space-between;
}

#marker-panel-top-row  {
    display: flex;
    flex-direction: column;
    flex: 1;
}

#marker-panel-top-inner-row  {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

#marker-panel-left-wrapper  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.marker-panel-left-wrapper-half  {
    flex-basis: 48%;
}

.marker-panel-left-wrapper-full {
    flex-basis: 100%;
}

#marker-panel-right-wrapper  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 48%;
}

.marker-heading  {
    @include default_text;
    color: #222222;
    font-size: 18px;
    letter-spacing: 1px;
    margin-top: 0;
    margin-bottom: 25px;
    font-weight: 600;
    text-align: center;
}

.marker-heading-lowered  {
    margin-bottom: 0;
}

.marker-form-wrapper  {
    text-align: center;
}

.marker-error-message  {
    color: #ff0000;
    font-weight: 600;
    text-align: center;
}

#marker_description  {
    height: 65px;
    max-width: 300px;
}

#marker-dragging-notice-wrapper  {
    border: 2px solid #ff0000;
    padding: 10px;
}

.new-form  {
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
}

.marker-additional-info  {
    margin-top: 5px;
    margin-bottom: 10px;
}

.camera-log  {
    display: block;
}

.marker-bottom-menu-wrapper  {
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
    flex-wrap: wrap;
}

.marker-bottom-form-title  {
    font-weight: 600;
    text-align: center;
    margin-top: 0;
}

#marker-color-picker-wrapper  {
    max-width: 240px;
    margin: auto;
    margin-bottom: 20px;
}

.marker-bottom-buttons-wrapper  {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.marker-bottom-form-button  {
    padding: 8px;
    background-color: #2ea44f;
    color: #fff;
    font-weight: 600;
    border: 0;
    border-radius: 5px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    min-width: 60px;
}

.marker-bottom-form-button:hover  {
    color: #c6c6c6;
}

.marker-bottom-menu-item  {
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin: 5px;
}

#marker-bottom-menu-item-large  {
    width: 40px;
    height: 40px;
    margin-bottom: 15px;
}

#marker-dragging-infowindow  {
    text-align: center;
}

#map-import-wrapper  {
    text-align: center;
}

#drop-zone  {
    width: 215px;
    height: 100px;
    border: 2px dashed;
    border-radius: 2px;
    margin-bottom: 15px;
}

#drop-zone:focus  {
    outline: 1px solid blue;
    border: 2px solid;
}

.drop-container {
    position: relative;
    display: flex;
    gap: 10px;
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 200px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
  }
  
  .drop-container:hover {
    background: #eee;
    border-color: #111;
  }
  
  .drop-container:hover .drop-title {
    color: #222;
  }
  
  .drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
  }
  
  input[type=file] {
    width: 260px;
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #555;
  }
  
  input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #084cdf;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
  }
  
  input[type=file]::file-selector-button:hover {
    background: #0d45a5;
  }

.marker-image  {
    cursor: pointer;
    max-width: 100%;
    max-height: 200px;
}

#icon-button-file, #icon-button-file2  {
    display: none;
}

#user-image-preview  {
    display: block;
    margin: auto;
    max-height: 400px;
    max-width: 90%;
    margin-bottom: 25px;
}

.upload-files-list  {
    @include default_text;
    font-size: 16px;
    text-align: center;
}

#marker_select  {
    margin-top: 10px;
    margin-bottom: 25px;
}

// Karuzela

.carousel {
    overflow: hidden;
}
  
.inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    transition: transform 0.3s;
}
  
.carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
}
  
.indicators {
    display: flex;
    justify-content: center;
}
  
.indicators > button {
    margin: 5px;
    //padding: 5px;
    border: 1px solid #9c9c9c;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    font-weight: 500;
    background-color: #dadada;
    cursor: pointer;
}

.indicators > button:hover  {
    background-color: #79b8a8;
}
  
.indicators > button.active {
    background-color: #79b8a8;
    color: #fff;
    font-weight: 600;
}

.carousel-image-wrapper  {
    position: relative;
}

.carousel-image-delete-button  {
    position: absolute;
    right: 5px;
    top: 5px;
    max-width: 30px;
    padding: 5px;
    background-color: #fff;
    cursor: pointer;
}

#carousel-slide-counter  {
    margin-top: 10px;
    margin-bottom: 5px;
    @include default_text;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.pagination-wrapper {
    font-size: 0;
    text-align: center;
  }

  .pagination-container  {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @keyframes pagination-container--animation-prev {
    0% { transform: translateX(0); }
    100% { transform: translateX(18px); }
  }
  
  @keyframes pagination-container--animation-next {
    0% { transform: translateX(0); }
    100% { transform: translateX(-18px); }
  }
  
  .transition-prev .pagination-container {
    animation: pagination-container--animation-prev 0.3s forwards;
  }
  
  .transition-next .pagination-container {
    animation: pagination-container--animation-next 0.3s forwards;
  }
  
  .little-dot {
    width: 6px;
    height: 6px;
    background: #707070;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin: 0 6px;
    position: relative;
    z-index: 10;
  }
  
  .little-dot--first,
  .little-dot--last {
    z-index: 5;
  }
  
  @keyframes slideLeft {
    0% {
      transform: translateX(0px);
    }
  
    100% {
      transform: translateX(-18px);
    }
  }
  
  .transition-prev .little-dot--first {
    animation: slideLeft 0.4s 0.3s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  @keyframes little-dot--first--animation {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .transition-next .little-dot--first {
    animation: little-dot--last--animation 0.3s forwards;
  }
  
  @keyframes little-dot--last--animation {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .transition-prev .little-dot--last {
    animation: little-dot--last--animation 0.3s forwards;
  }
  
  @keyframes slideRight {
    0% {
      transform: translateX(0px);
      opacity: 1;
    }
  
    100% {
      transform: translateX(18px);
      opacity: 1;
    }
  }
  
  .transition-next .little-dot--last {
    animation: slideRight 0.4s 0.3s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .big-dot {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: #089992;
    position: absolute;
    right: -6px;
    top: -3px;
  }
  
  .transition-next .big-dot {
    right: auto;
    left: -6px;
  }
  
  .big-dot-container {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    position: absolute;
    right: 3px;
    z-index: 10;
    transform-origin: revert-layer;
  }
  
  .transition-next .big-dot-container {
    right: auto;
    left: 3px;
  }
  
  @keyframes big-dot-container--animation-prev {
    100% { transform: rotate(-360deg) translateX(-18px); }
  }
  
  @keyframes big-dot-container--animation-next {
    100% { transform: rotate(360deg) translateX(18px); }
  }
  
  .transition-prev .big-dot-container {
    animation: big-dot-container--animation-prev 0.5s forwards;
  }
  
  .transition-next .big-dot-container {
    animation: big-dot-container--animation-next 0.5s forwards;
  }
  
  .btn {
    fill: #707070;
    cursor: pointer;
    transition: opacity 0.2s;
  }
  
  .btn:hover {
    opacity: 0.6;
  }
  
  .btn--next {
    left: calc(100% + 20px);  
  }
  
  .btn--prev {
    right: calc(100% + 20px);
  }

#archive-message  {
    text-align: center;
    color: #FF0000;
}

// Mapa 

#add-context-menu-wrapper  {
    border-radius: 10px;
    width: 170px;
    height: 100px;
    display: none;
    background-color: #fff;
    position: absolute;
    z-index: 10;
    box-shadow: 0 10px 20px 1px rgba(0,0,0,.3);
    -webkit-box-shadow: 0 10px 20px 1px rgba(0,0,0,.3);
    -moz-box-shadow: 0 10px 20px 1px rgba(0,0,0,.3);
}

#add-context-menu-wrapper:after {
    content: ""; 
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 15px solid #fff;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
}

.context-menu-item  {
    font-family: 'Roboto';
    user-select: none;
    text-align: center;
    cursor: pointer;
    margin: 0;
    padding: 15px;
}

.context-menu-item:hover  {
    border-radius: 10px;
    background-color: #f1f1f1;
}

#add-marker-spinner-wrapper, #center-map-wrapper {
    display: none;
    position: absolute;
}

#center-map-wrapper {
    font-weight: 600;
    transform: translate(10px, 10px);
}

#add-marker-spinner-wrapper svg {
    width: 40px;
    height: 40px;
}

.marker-label  {
    font-size: 16px!important;
    font-weight: 600;
    position: absolute;
    bottom: 50px;
    transform: translate(calc(-50% + 8px));
}

.marker-info-window  {
    margin: 12px;
}

.camera-remove-window-wrapper  {
    margin-left: 10px;
    margin-right: 10px;
}

// Przyciski do kamer

#camera-buttons-wrapper  {
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
}

.camera-button  {
    padding: 8px 15px 8px 15px;
    cursor: pointer;
    border: 0;
    border-radius: 5px;
    background-color: #2c974b;
    @include default_text;
    color: #fff;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;

}

.camera-button img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.camera-button:hover  {
    background-color: #008d67;
    font-weight: 600;
}

.camera-form-label  {
    @include default_text;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
}

.camera-form-value  {
    font-family: 'Roboto';
    margin: 0;
    text-align: right;
}

.camera-add-form  {
    margin-top: 5px;
    width: 250px;
    max-width: 250px;
}

#comment  {
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 100px;
}

#mounting_point, #platform  {
    margin-top: 10px;
    width: 250px;
}

// Formularz kamery

.camera-form-header  {
    padding: 25px;
    background-color: #79b8a8;
}

#add-camera-form-body  {
    padding: 20px;
    padding-top: 10px;
}

.camera-form-input-wrapper  {
    display: flex;
    justify-content: flex-end;
}

.camera-form-button-wrapper  {
    display: flex;
    justify-content: flex-end;
    padding-top: 25px;
    padding-right: 10px;
}

.camera-form-error  {
    @include default_text;
    color: #ff0000;
    font-weight: 600;
    text-align: right;
    font-size: 16px;
}

#camera-log-title  {
    margin-top: 10px;
    margin-bottom: 5px;
}

#camera-log  {
    margin-top: 5px;
    margin-bottom: 10px;
    padding-left: 15px;
}

.camera-form-wrapper  {
    padding-top: 25px;
    padding-bottom: 10px;
}

// Modal

.modal-overlay  {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(25, 25, 25, 0.6);
    z-index: 1200;
}

.modal  {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 25px;
    min-width: 300px;
    max-width: 90vw;
    max-height: 90vh;
    border-radius: 15px;
}

.modal-title  {
    @include default_text;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    flex-basis: 80%;
    margin: 0;
    text-align: center;
    color: #fff;
}

.modal-header  {
    padding: 25px;
    background-color: #79b8a8;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.modal-body  {
    overflow-y: auto;
}

.modal-footer  {
    margin-left: 10px;
    margin-right: 10px;
}

.modal-message-container  {
    padding: 0px 20px 0px 20px;
}

.form-line-wrapper  {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 25px;
}

.new-form  {
    padding: 5px;
    height: 25px;
    display: block;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.15);
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.15);
}

.form-label  {
    @include default_text;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}

.form-label-message  {
    @include default_text;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
}

.form-label-error-message  {
    color: #ff0000;
}

.form-waiting-message  {
    margin-bottom: 0;
}

.export-line-wrapper  {
    padding: 10px 25px;
}

#project_name  {
    width: 80%;
}

#map_rename  {
    width: 80%;
}

#project_description  {
    max-width: 100%;
    width: 80%;
    height: 90px;
    max-height: 150px;
    font-family: 'Roboto';
    font-size: 14px;
}

.form-button-wrapper  {
    display: flex;
    justify-content: flex-end;
    padding: 25px;
    padding-bottom: 0;
}

.form-button  {
    cursor: pointer;
    padding: 8px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    @include default_text;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}

.form-button-disabled  {
    padding: 8px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: #bbbbbb;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    @include default_text;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}

.form-button:hover {
    background-color: #2c974b;
}

.axios-error  {
    @include default_text;
    color: #ff0000;
    text-align: center;
    font-size: 16px;
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.axios-error-2  {
    margin-top: 5px;
}

.modal-spinner-container  {
    margin-bottom: -25px;
}

.spinner-container  {
    text-align: center;
}

.delete-form-line  {
    display: flex;
    padding: 25px;
    padding-bottom: 0;
}

.delete-name  {
    font-family: 'Roboto';
    margin: 0;
    margin-left: 25px;
}

// Login

#login-outer-container  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

#login-inner-container  {
    max-width: 350px;
    min-width: 350px;
    margin: 25px;
    @include shadow;
    padding: 50px;
}

#login-form-title  {
    @include default_text;
    font-weight: 600;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 25px;
}

.login-form-label  {
    @include default_text;
    margin-top: 15px;
    margin-bottom: 10px;
}

.login-form  {
    border: 0;
    border-bottom: 2px solid #222222;
    line-height: 26px;
}

.login-form:focus  {
    outline: 0;
    border-bottom: 3px solid #0014c6;
}

#send-login  {
    margin-top: 25px;
    padding: 10px 15px 10px 15px;
    background-color: #79b8a8;
    border: 0;
    border-radius: 15px;
    cursor: pointer;
    @include default_text;
    font-weight: 600;
    color: #fff;
}

#send-login:hover  {
    background-color: #629d8e;
    font-weight: 700;
    color: rgb(225, 224, 224);
}

.send-login-no-margin  {
    margin-top: 0!important;
}

#login-message  {
    @include default_text;
    color: #ff0000;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 0;
    text-align: center;
}

// App 1

#app1-outer-container  {
    display: flex;
    margin: auto;
    margin-top: 25px;
    max-width: 1200px;
    width: 95%;
    justify-content: space-between;
    align-items: stretch;
    flex: 1 1;
}

#app1-side-container  {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-basis: 25%;
    @include shadow;
    align-items: center;
    justify-content: center;
}

#app1-datetime  {
    @include default_text;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
    letter-spacing: 1px;
    text-align: center;
}

.app1-main-button, .app1-sub-button  {
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 10px;
    cursor: pointer;
    border: 0;
    border-radius: 5px;
    font-family: Verdana;
    font-weight: 600;
    color: #000;
    letter-spacing: 0.5px;
}

.app1-main-button  {
    width: 150px;
    background-color: #89d8ba;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);  
}

.app1-main-button-centered  {
    margin: auto;
    display: block;
}

.app1-main-button-active  {
    background-color: #79b8a8;
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.5);
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.5); 
}

.app1-sub-button  {
    width: 180px;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #89c0d8;
    margin-top: 10px;
    margin-bottom: 10px;
}

.app1-main-button:hover  {
    background-color: #79b8a8;
    color: #222;
}

#app1-main-container  {
    display: flex;
    flex-basis: 100%;
    padding: 25px;
    @include shadow;
    flex-direction: column;
    flex: 1 1;
}

.app-main-buttons-wrapper  {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 15px;
}

#app1-sub-panel  {
    display: flex;
    height: max-content;
}

.app1-sub-container  {
    display: flex;
    flex-basis: 100%;
    justify-content: space-around;
    align-content: flex-start;
    flex-wrap: wrap;
    height: max-content;
    margin-top: 30px;
}

#app1-info-container  {
    display: flex;
    flex-basis: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding-top: 20px;
}

#app1-camera-preview-image  {
    width: 90%;
}

.app1-accept-button  {
    display: block;
    //margin: auto;
    margin-top: 25px;
    padding: 8px 20px 8px 20px;
    background-color: #50cd6d;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    @include default_text;
    font-weight: 600;
    color: #000;
}

.app1-accept-button:hover  {
    background-color: #58c3a8;
    font-weight: 700;
    color: #222;
}

.app1-text  {
    @include default_text;
    font-weight: 600;
    text-align: center;
}

.raspberry-message-span  {
    display: block;
    margin: 10px;
}

.raspberry-message-span-centered  {
    text-align: center;
}

.camera-info  {
    margin-bottom: 0;
}

.app1-error-message  {
    @include default_text;
    font-weight: 600;
    color: #ff0000;
    margin: 0;
    margin-top: 15px;
    text-align: center;
}

.app1-error-margins  {
    margin-top: 20px;
    margin-bottom: 10px;
}

#ip_cam  {
    height: 40px;
    border: 2px solid #89c0d8;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.camera-panel-overlay-wrapper  {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    z-index: 1200;
    overflow: scroll;
    padding-bottom: 25px;
    padding-top: 10px;
}

.camera-panel-overlay-inside  {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}


// Camera add panel

#camera-panel-wrapper  {
    width: 100%;
    margin-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    justify-content: center;
    display: flex;
    flex: 1;
}

.camera-panel-outer-container  {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.camera-panel-top-wrapper  {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-bottom: 2px solid #89d8ba;
}

.camera-panel-info-text  {
    @include default_text();
    text-align: center;
}

#camera-panel-info-map-name  {
    margin-top: 0;
}

.camera-panel-open-button  {
    padding: 10px 20px 10px 20px;
    border: 0;
    background-color: #089992;
    @include default_text();
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.75);  
    cursor: pointer;
}

.camera-panel-open-button-smaller  {
    padding: 7px 15px 7px 15px;
    font-size: 16px;
}

.camera-panel-middle-wrapper  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    
}


.camera-panel-warning-message  {
    @include default_text();
    color: #ff0000;
    text-align: center;
}

#camera-panel-warning-info  {
    margin: 0;
    line-height: 24px;
}

.camera-panel-warning-message-smaller-margins  {
    margin: 0;
}

.camera-panel-platform-detailed-wrapper  {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.camera-panel-platform-detailed-button-wrapper  {
    display: flex;
    flex-direction: column;
}



.cam-form-field  {
    height: 50px;
    text-align: center;
    @include default_text();
    border: 1px solid #888;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.51) inset;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.51) inset;
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.51) inset;
}

.cam-form-field-textarea  {
    max-width: 100%;
    height: 100px;
    text-align: left;
    padding: 10px;
}

.cam-accept-button, .cam-cancel-button, .camera-panel-button-selection  {
    display: block;
    margin-top: 25px;
    padding: 8px 20px 8px 20px;
    background-color: #50cd6d;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    font-family: Verdana;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-weight: 600;
    color: #000;
    width: 150px;
}

.cam-accept-button-add  {
    width: 200px;
    height: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.cam-accept-button-disabled  {
    //background-color: #777;
    background-color: #91a395;
}

.cam-cancel-button  {
    background-color: #ff3636;
    width: 150px;
}

.camera-panel-buttons-selection-wrapper  {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}

.camera-panel-buttons-selection-wrapper-column  {
    flex-direction: column;
}

.camera-panel-button-selection  {
    width: 150px;
    height: 60px;
    margin: 10px;
    //@include default_text();
    //color: #555;
    font-family: Verdana;
    font-weight: 600;
    font-size: 15px;
    color: #000;
    letter-spacing: 0.2px;

}

.camera-panel-bottom-wrapper  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.camera-panel-button-selected  {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.51) inset;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.51) inset;
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.51) inset;
    background-color: #1aad3c;
}

#add_camera_image_preview  {
    max-width: 80%;
    max-width: min(80%, 500px); 
}

.camera-panel-platform-button-title  {
    font-family: 'roboto';
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin-bottom: 5px;
}

.camera-panel-button-arrow  {
    font-size: 20px;
    font-weight: 700;
    max-width: 45px;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

}

// Demontaż kamer

#camera-disassembly-outer-container  {
    display: flex;
    flex: 1;
    max-width: 1000px;
    margin: auto;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

#camera-disassembly-top-panel-container  {
    display: flex;
    //flex: 1;
    justify-content: center;
}

.camera-disassembly-top-panel-wrapper  {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
}

.camera-disassembly-top-panel-title  {
    @include default_text();
    font-weight: 600;
    margin-right: 25px;
}

.camera-disassembly-select {
    width: 200px;
    padding: 5px;
    font-size: 16px;
    line-height: 1;
    border: 0;
    border-radius: 5px;
    height: 34px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
    -webkit-appearance: none;
    background-position-x: 176px;
}

.camera-disassembly-remove-buttons-container  {
    display: flex;
    flex-direction: column;
}

.camera-disassembly-remove-buttons-wrapper  {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: min-content;
    min-width: 600px;
    margin: auto;
}

.camera-disassembly-remove-platform-title  {
    @include default_text();
    font-weight: 600;
    text-align: center;
    border-bottom: 2px solid #89d8ba;
    padding-bottom: 10px;
}

.camera-disassembly-button  {
    width: 90px;
    margin: 10px 30px 10px 30px;
}

.camera-disassembly-button-disabled  {
    background-color: #aaa;
}

.camera-disassembly-button-disabled:hover, .camera-disassembly-button-disabled:active  {
    background-color: #aaa;
}

.camera-disassembly-button-error  {
    background-color: #ff0000;
}

#disassembly-camera-confirmation-buttons-wrapper  {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.disassembly-camera-confirmation-buttons-wrapper-extended  {
    margin-bottom: 40px;
}
    
/*

padding: 15px 5px 15px 5px;
    border: 0;
    background-color: #89d8ba;
    border-radius: 5px;
    margin: 10px;
    @include default_text();
    font-weight: 600;
.camera-disassembly-select  {
    background-color: #89d8ba;
    color: #000;
    padding: 10px;
    width: 200px;
    border: none;
    font-size: 20px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: button;
    outline: none;
}

.camera-disassembly-select-wrapper::before {
    content: "\f13a";
    font-family: FontAwesome;
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    height: 100%;
    text-align: center;
    font-size: 28px;
    line-height: 45px;
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.1);
    pointer-events: none;
}
  
.camera-disassembly-select-wrapper:hover::before {
    color: rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .camera-disassembly-select option {
    padding: 20px;
  }
*/










// Responsywne

@media screen and (max-width: 1100px) {
    .menu-toggleable  {
        z-index: 100;
    }
    #side-panel  {
        flex-basis: 32.5%;
    }
    #main-panel  {
        flex-basis: 65%;
    }
}

@media screen and (max-width: 1000px) {
    #map-outer-container  {
        flex-direction: column;
    }
    #layer-container  {
        min-height: 200px;
    }
    #side-panel  {
        margin-bottom: 25px;
    }
    .side-panel-active  {
        max-height: none;
    }
    #menu-large-screen  {
        display: none;
    }
    header {
        display: none;
    }
    .logo  {
        margin: 0;
        margin-top: 25px;
        margin-bottom: 40px;
    }
    .main-wrapper-lowered  {
        margin-top: 70px;
    }
    .layer-sort-wrapper  {
        justify-content: center;
    }
    .layer-sort-wrapper select  {
        margin-left: 25px;
    }
    #marker-bottom-menu-widescreen  {
        display: none;
    }
    .camera-button  {
        font-size: 16px;
        width: 120px;
    }
    #marker-panel  {
        min-height: auto;
    }

    #marker-panel-top-inner-row  {
        flex-direction: column;
    }
    
    .marker-panel-left-wrapper-half  {
        flex-basis: 100%;
    }
     
    #marker-panel-right-wrapper  {
        flex-basis: 100%;
        margin-top: 25px;
    }
}

@media screen and (min-width: 1001px) {
    .side-panel-active  {
        position: sticky;
        top: 0;
    }
    #menu-small-screen  {
        display: none;
    }
    #marker-bottom-menu-smallscreen  {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    #app1-outer-container  {
        flex-direction: column;
    }
    #app1-main-container  {
        padding: 15px;
    }
    .app-main-buttons-wrapper {
        justify-content: space-around;
    }
    .app1-sub-container  {
        margin-top: 15px;
    }
}

@media screen and (max-width: 700px) {

    #logout  {
        margin-top: 25px;
    }
    #marker-panel  {
        min-height: auto;
    }
    #marker-panel-top-inner-row   {
        flex-direction: column;
    }
    #marker-panel-left-wrapper  {
        text-align: center;
    }
    #marker-panel-right-wrapper  {
        margin-top: 25px;
    }
    .marker-bottom-menu-wrapper  {
        justify-content: center;
    }
    .marker-bottom-menu-item  {
        margin: 10px;
    }
    .marker-image  {
        max-width: 400px;
        width: 90%;
        max-height: 200px;
    }
    #markers-photos-wrapper  {
        text-align: center;
    }
    .carousel-image-delete-button  {
        top: 6px;
        right: 7%;
    }
    .indicators  {
        margin-top: 15px;
    }
    
    .admin-menu-item  {
        margin: 10px;
    }
    #camera-buttons-wrapper  {
        flex-direction: column;
        align-items: center;
    }
    
    #admin-user-table  {
        display: none;
    }

    .admin-user-table-mobile  {
        display: table;
    }
    #add-user-button  {
        margin-top: 0;
    }
    #search-bar-wrapper  {
        top: 55px;
        left: 10px;
    }
    #camera-disassembly-top-panel-container  {
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 25px;
    }
    .camera-disassembly-remove-buttons-wrapper  {
        min-width: 500px;
    }
   
}

@media screen and (max-width: 500px) {
    #admin-menu-wrapper  {
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }
    #admin-inner-container  {
        padding: 10px;
    }
    #admin-user-table th, td {
        padding: 3px;
    }
    #admin-site-title  {
        margin-top: 10px;
    }
    #add-user-button  {
        margin-bottom: 15px;
    }
    .assembly-menu-modal  {
        width: 95vw;
    }
    .app1-main-button  {
        margin: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .camera-panel-button-arrow  {
        max-width: 30px;
    }
    .camera-disassembly-remove-buttons-wrapper  {
        min-width: 330px;
    }
    .camera-disassembly-button  {
        margin: 7px;
    }
    .app1-main-button-centered  {
        margin: auto;
    }
}

// 404

#text-404  {
    @include text_header;
    color: #df5e23;
}

.image-container {
    width: 100%;
    text-align: center;
}

.full-image {
    width: 100%;
    max-width: 500px;
}

// Footer

#footer-wrapper  {
    margin: 25px;
    margin-bottom: 0;
    border-top: $border;
}

#footer-text  {
    @include default_text;
    font-weight: 600;
    text-align: center;
    margin: 25px;
}